@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@media (max-width: 639px) {
  .leaflet-control-attribution {
    bottom: 65px;
  }
}

body,
main {
  @apply bg-white text-black dark:text-white dark:bg-dark;
}

.center-marker {
  background: url('/assets/current-position.svg') center center no-repeat;
  background-size: contain;
  width: 32px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaflet-popup-content-wrapper {
  background: none !important;
  border-radius: 0.375rem;
  padding: 0 !important;
  margin: 0 !important;
}

.leaflet-popup-content {
  margin: 0 !important;
}

.leaflet-popup-tip-container {
  top: 50%;
  left: 103% !important;
  transform: rotate(-90deg);
  display: none;
}

.skeleton-loading {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.horizontal-snap {
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
}

.horizontal-snap > .card {
  scroll-snap-align: start;
}

.horizontal-snap .card {
  width: 260px;
  max-width: none;
  object-fit: contain;
}

.image-slider {
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  height: calc(300px + 1rem);
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
}
.image-slider > .card {
  scroll-snap-align: start;
}

.rangeInput .slider::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 70%;
  background: #4928ce;
  pointer-events: auto;
  border: solid 4px white;
  -webkit-appearance: none;
}

.slider.dragging::-webkit-slider-thumb,
.slider:hover::-webkit-slider-thumb {
  box-shadow: 0 0 5px 5px #4928ce;
}

.rangeInput .slider::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 70%;
  background: #4928ce;
  pointer-events: auto;
  border: solid 4px white;
  -moz-appearance: none;
}

.slider.dragging::-moz-range-thumb,
.slider:hover::-moz-range-thumb {
  box-shadow: 0 0 5px 5px #4928ce;
}

.rangeInput .slider::-ms-thumb {
  height: 24px;
  width: 24px;
  border-radius: 70%;
  background: #4928ce;
  pointer-events: auto;
  border: solid 4px white;
  pointer-events: auto;
}

.slider.dragging::-ms-thumb,
.slider:hover::-ms-thumb {
  box-shadow: 0 0 5px 5px #4928ce;
}

.rangeInput input {
  @apply absolute w-full h-[5px] top-[-5px] bg-transparent cursor-pointer pointer-events-none appearance-none;
}

.tooltip {
  @apply  text-black dark:text-white bottom-2 absolute p-1 pointer-events-none w-[40px] text-center;
}
